<script setup lang="ts">
/**
 * Translation.
 */
const { t } = useI18n()

/**
 * Container.
 */
const container = ref<HTMLElement>()

/**
 * Scroll to the top of the panel.
 */
function scrollToTop() {
   container.value?.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
   })
}
</script>

<template>
   <div class="relative h-full shadow">
      <div class="absolute bottom-full right-0 z-[1] block md:hidden">
         <UiButton
            class="border-none py-3"
            @click="scrollToTop"
         >
            <Icon
               name="heroicons-solid:chevron-up"
            />
            <span class="sr-only">{{ t('global.collapse') }}</span>
         </UiButton>
      </div>

      <div
         ref="container"
         class="@container first:*:min-h-full first:*:p-6 md:h-full md:overflow-y-auto md:first:*:p-12"
      >
         <slot />
      </div>

      <PanelStateManager class="absolute right-0 top-5 z-[2] hidden translate-x-1/2 md:block" />
   </div>
</template>
