<script setup lang="ts">
const { t } = useI18n()

const open = ref(false)
</script>

<template>
   <div class="self-start bg-gray-100 shadow">
      <button
         class="flex w-full items-center gap-4 border-b bg-white px-4 py-3 xl:justify-between"
         @click="open = !open"
      >
         <span class="truncate font-bold uppercase text-link max-xl:order-2">
            {{ t('global.map-tools') }}
         </span>

         <Icon
            class="shrink-0"
            :name="open ? 'heroicons-solid:chevron-up' : 'heroicons-solid:chevron-down'"
         />
      </button>

      <Suspense>
         <UiExpandable
            :open="open"
         >
            <div class="flex divide-x-2 py-6 *:px-6">
               <MapOptionsMainLayers />
               <MapOptionsExtraLayers />
            </div>
         </UiExpandable>
      </Suspense>
   </div>
</template>
